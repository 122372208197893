<mat-dialog-content>
  <h1 mat-dialog-title>
    <span *ngIf="mode === 'CREATE'">Create New Appeal Level</span>
    <span *ngIf="mode !== 'CREATE'">Edit Appeal Level</span>
  </h1>
  <div style="width: 100%; text-align: center; margin-bottom: 5px;">
    <span *ngIf="mode !== 'CREATE'">{{appealLevelType}}</span>
  </div >
  <!-- Original functionality had the user pick the next appeal level. It has been changed to have the system do it automatically
  <div class="create-form">
    <form [formGroup]="formGroup" class="container create-form">
      <mat-form-field appearance="outline" class="type-input">
        <mat-label>Appeal Level Type</mat-label>
        <mat-select
          formControlName="appealLevelType"
          >
          <mat-option *ngFor="let alt of typeList" [value]="alt.value">{{ alt.label }}</mat-option>
        </mat-select>
      </mat-form-field>     
    </form>
  </div> -->
  <div class="create-form">
    <form [formGroup]="formGroup" class="container create-form">
      <mat-form-field appearance="outline" class="type-input">
        <mat-label>Appeal Type</mat-label>
        <mat-select
          formControlName="appealType"
          >
          <mat-option *ngFor="let alt of appealTypeList" [value]="alt.value">{{ alt.label }}</mat-option>
        </mat-select>
      </mat-form-field>     
    </form>
  </div>  
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
  <!--
    <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent">
        <mat-icon>save</mat-icon> Create
    </button> -->

    <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent">
      <mat-icon>save</mat-icon>
      <span *ngIf="mode === 'CREATE'">Create Next Appeal Level</span>
      <span *ngIf="mode !== 'CREATE'">Save</span>
  </button>

    <button mat-button mat-stroked-button (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>

