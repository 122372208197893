import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AttachmentComponent } from '@app/attachment/attachment.component';
import { Logger, BaseComponent, FennecSnackbarService } from "xf-common";

@Component({
  selector: 'app-activity-diary-attachment-dialog',
  templateUrl: './activity-diary-attachment-dialog.component.html',
  styleUrls: ['./activity-diary-attachment-dialog.component.scss']
})
export class ActivityDiaryAttachmentDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
  log: Logger = new Logger("ActivityDiaryAttachmentDialogComponent");
  // height: any;
  // width: any;
  // lockAxis: any;

  attachmentId: number = -1;
  miCaseId: number = -1;
  @ViewChild('attachmentComponent')
  attachmentComponent?: AttachmentComponent;
  context = this.data.activityDiaryDomain;

  // Resize window
  // @ViewChild('resizeBox') resizeBox?: ElementRef;
  // @ViewChild('dragHandleCorner') dragHandleCorner?: ElementRef;
  // @ViewChild('dragHandleRight') dragHandleRight?: ElementRef;
  // @ViewChild('dragHandleBottom') dragHandleBottom?: ElementRef;

  // get resizeBoxElement(): HTMLElement {
  //   return this.resizeBox?.nativeElement;
  // }

  // get dragHandleCornerElement(): HTMLElement {
  //   return this.dragHandleCorner?.nativeElement;
  // }

  // get dragHandleRightElement(): HTMLElement {
  //   return this.dragHandleRight?.nativeElement;
  // }

  // get dragHandleBottomElement(): HTMLElement {
  //   return this.dragHandleBottom?.nativeElement;
  // }


  constructor(
  @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<ActivityDiaryAttachmentDialogComponent>,
    protected snack: FennecSnackbarService,
    // private ngZone: NgZone
  ) {
    super();
  }

  ngAfterViewInit() {
    // this.setAllHandleTransform();
   }

  ngOnInit() {
    this.attachmentId = this.data.attachmentId;
    this.miCaseId = this.data.miCaseId;
  }

  onClose() {
    this.dialogRef.close();
  }

  // setAllHandleTransform() {
  //   const rect = this.resizeBoxElement.getBoundingClientRect();
  //   this.setHandleTransform(this.dragHandleCornerElement, rect, 'both');
  //   this.setHandleTransform(this.dragHandleRightElement, rect, 'x');
  //   this.setHandleTransform(this.dragHandleBottomElement, rect, 'y');
  // }

  // setHandleTransform(
  //   dragHandle: HTMLElement,
  //   targetRect: DOMRect,
  //   position: 'x' | 'y' | 'both'
  // ) {
  //   const dragRect = dragHandle.getBoundingClientRect();
  //   const translateX = targetRect.width - dragRect.width;
  //   const translateY = targetRect.height - dragRect.height;

  //   if (position === 'x') {
  //     dragHandle.style.transform = `translate3d(${translateX}px, 0, 0)`;
  //   }

  //   if (position === 'y') {
  //     dragHandle.style.transform = `translate3d(0, ${translateY}px, 0)`;
  //   }

  //   if (position === 'both') {
  //     dragHandle.style.transform = `translate3d(${translateX}px, ${translateY}px, 0)`;
  //   }
  // }

  // dragMove(dragHandle: HTMLElement, $event: CdkDragMove<any>) {
  //   this.ngZone.runOutsideAngular(() => {
  //     this.resize(dragHandle, this.resizeBoxElement);
  //   });
  // }

  // resize(dragHandle: HTMLElement, target: HTMLElement) {
  //   const dragRect = dragHandle.getBoundingClientRect();
  //   const targetRect = target.getBoundingClientRect();

  //   const width = dragRect.left - targetRect.left + dragRect.width;
  //   const height = dragRect.top - targetRect.top + dragRect.height;

  //   target.style.width = width + 'px';
  //   target.style.height = height + 'px';

  //   this.setAllHandleTransform();
  // }

}
