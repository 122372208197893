import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, DataResponse, Logger, PagedResponse } from 'xf-common';

@Injectable({
  providedIn: 'root'
})
export class MiCaseAppealLevelService extends BaseService {

  baseEndpoint = this.apiEndpoint("mi-case-appeal-level");
  
  constructor(
    protected override httpClient: HttpClient
  ) {
    super(new Logger("MICaseAppealLevelService"));
  }

  getAppealLevelTypes = (): Observable<DataResponse<any>> => {
    return this.httpClient.get<any>(`${this.baseEndpoint}/appeal-level-types`);
  }

  getAppealTypes = (): Observable<DataResponse<any>> => {
    return this.httpClient.get<any>(`${this.baseEndpoint}/appeal-types`);
  }  

  getAppealLevelsForMICase = (miCaseId: number, first: number, max: number): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(`${this.baseEndpoint}/list/${miCaseId}?first=${first}&max=${max}`);
  }

  create = (packet: any): Observable<DataResponse<any>> => {
    return this.httpClient.post<any>(`${this.baseEndpoint}/create`, packet);
  }

  update = (packet: any): Observable<DataResponse<any>> => {
    return this.httpClient.put<any>(`${this.baseEndpoint}/update`, packet);
  }

  delete = (appealLevelId: number): Observable<DataResponse<any>> => {
    return this.httpClient.delete<any>(`${this.baseEndpoint}/${appealLevelId}`);
  }  

  /* Note: update packet is sent up but the only data element used for this call is the miCaseId. The 'next'
     appeal level is auto calculated by the back-end. */
  createNext = (miCaseId: number, appealType: string): Observable<DataResponse<any>> => {
    return this.httpClient.post<any>(`${this.baseEndpoint}/create-next/${appealType}/${miCaseId}`, {});
  }

}
