
<mat-toolbar class="app-toolbar horizontal-nav">
  <button
    *ngIf="deviceIsMobile"
    mat-icon-button
    class="navbar-icon"
    aria-label="Open navigation drawer"
    (click)="sidenav.toggle()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <div class="title">
    <a href="">Fennec</a>
<!--    <img class="fennec-logo" src="../assets/logo.png">-->
  </div>
  <!-- <div class="fennec-text">
    <img src="../assets/FennecText.png">
  </div> -->
  <!-- <div class="fennec-logo">
    <img src="../assets/logo.png">
  </div> -->
<!--  <div class="user-welcome">-->
<!--    <h2 *ngIf="username">Welcome, {{ username }}</h2>-->
<!--  </div>-->

  <ng-container *ngIf="!deviceIsMobile" [ngTemplateOutlet]="navList"></ng-container>

  <span class="spacer" *ngIf="deviceIsMobile"></span>

  <div class="user-logout">
    <button id="fennec-logout-button" mat-icon-button (click)="logout()" matTooltip="Logout">
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-sidenav-container #sidenavContainer [hasBackdrop]="false">
  <mat-sidenav mode="side" class="vertical-nav" #sidenav>
    <ng-container *ngIf="deviceIsMobile" [ngTemplateOutlet]="navList"></ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #navList>
  <mat-nav-list [class.horizontal-nav]="!deviceIsMobile">
    <a mat-list-item routerLink="/notifications" [routerLinkActive]="['is-active']"
      *ngIf="userNotificationCount > 0"
      style="color: blue" class="animate-flicker"><mat-icon>notification_important</mat-icon>
    </a>
    <a mat-list-item routerLink="/" [routerLinkActive]="['is-active']" 
      [routerLinkActiveOptions]="{ exact: true }">Tasks
    </a>
    <a mat-list-item routerLink="/mi-case" [routerLinkActive]="['is-active']">Medical Incident Stays</a>
    <a mat-list-item routerLink="/providers" [routerLinkActive]="['is-active']">Providers</a>
    <a mat-list-item routerLink="/companies" [routerLinkActive]="['is-active']">Companies</a>
    <a mat-list-item routerLink="/health-care-plan" [routerLinkActive]="['is-active']">Plans</a>
    <a mat-list-item *ngIf="userIsPatientRead" 
      routerLink="/patients" [routerLinkActive]="['is-active']">Patients
    </a>
    <a *ngIf="userIsAdmin || userIsCrudAdmin" mat-list-item 
      routerLink="/admin" [routerLinkActive]="['is-active']">Admin
    </a>
    <a *ngIf="userIsAdmin" mat-list-item routerLink="/jsl" [routerLinkActive]="['is-active']">JSL</a>
    <a mat-list-item routerLink="/edi-run" [routerLinkActive]="['is-active']">EDI Run</a>
    <a mat-list-item routerLink="/activity-diary" [routerLinkActive]="['is-active']">Activity Diary</a>
    <a *ngIf="userIsAdmin" mat-list-item routerLink="/users" [routerLinkActive]="['is-active']">Users</a>
    <a *ngIf="userIsAdmin || userIsApeAdmin" mat-list-item routerLink="/ape" [routerLinkActive]="['is-active']">APE</a>
    <a *ngIf="userIsAdmin || userIsSystemAdmin" mat-list-item 
      routerLink="/system-configuration" [routerLinkActive]="['is-active']">System Config
    </a>
    <div class="spacer"></div>
    <a mat-list-item routerLink="/profile" [routerLinkActive]="['is-active']">Profile</a>
  </mat-nav-list>
</ng-template>
