import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CaseListComponent } from "./case-list/case-list.component";
import { CreateCaseComponent } from './case-create/case-create.component';
import { MICaseComponent } from "./mi-case.component";
import { CommonViewModule, MaterialModule, XfCommonModule } from 'xf-common';
import { RouterModule } from "@angular/router";
import { AttachmentModule } from "@app/attachment/attachment.module";
import { ItemizedListComponent } from "@app/itemized/itemized-list/itemized-list.component";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatGridListModule } from '@angular/material/grid-list';
import {
  ItemizedRevisionListComponent
} from "@app/itemized/itemized-revision/itemized-revision-list/itemized-revision-list.component";
import {
  ItemizedLineItemComponent
} from "@app/itemized/itemized-line-item/itemized-line-item/itemized-line-item.component";
import { ActivityDiaryListComponent } from "@app/activity-diary/activity-diary-list/activity-diary-list.component";
import { ItemizedRevisionService } from "@app/itemized/itemized-revision/itemized-revision.service";
import { XFWFModule } from "@app/xfwf/xfwf.module";
import { MatExpansionModule } from "@angular/material/expansion";
import {
  ItemizedLineItemGridComponent
} from "@app/itemized/itemized-line-item/itemized-line-item-grid/itemized-line-item-grid.component";
import { MiCaseGeneralComponent } from './mi-case-general/mi-case-general.component';
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { CaseEditComponent } from './case-edit/case-edit.component';
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommentsModule } from "@app/comments/comments.module";
import { CaseHoldDialogComponent } from './case-hold-dialog/case-hold-dialog.component';
import {
  ItemizedRevisionRevenueCodeSummaryComponent
} from "@app/itemized/itemized-revision/itemized-revision-revenue-code-summary/itemized-revision-revenue-code-summary.component";
import { MiCaseFinanceComponent } from './mi-case-finance/mi-case-finance.component';
import { MiCaseUsersComponent } from "./mi-case-users/mi-case-users.component";
import { PrimaryClientEditDialogComponent } from './primary-client-edit-dialog/primary-client-edit-dialog.component';
import { AddCommentComponent } from "@app/comments/add-comment/add-comment.component";
import { MiCaseReportElementsComponent } from './mi-case-report-elements/mi-case-report-elements.component';
import { MiCaseReportElementPublishDateEditDialogComponent } from './mi-case-report-elements/mi-case-report-element-publish-date-edit-dialog/mi-case-report-element-publish-date-edit-dialog.component';
import { MiCaseAppealLevelComponent } from "./mi-case-appeal-level/mi-case-appeal-level.component";
import { MiCaseAppealLevelCreateDialogComponent } from "./mi-case-appeal-level/mi-case-appeal-level-create-dialog/mi-case-appeal-level-create-dialog.component";
import { ItemizedRevisionSelectDialogComponent } from "../itemized/itemized-revision/itemized-revision-select-dialog/itemized-revision-select-dialog.component";

@NgModule({
    imports: [
        AttachmentModule,
        CommonModule,
        CommonViewModule,
        MatCardModule,
        MatDatepickerModule,
        MatGridListModule,
        MatNativeDateModule,
        MatRadioModule,
        MatTableModule,
        MaterialModule,
        RouterModule,
        XFWFModule,
        MatExpansionModule,
        CommentsModule,
        XfCommonModule
    ],
  declarations: [
    ActivityDiaryListComponent,
    CaseListComponent,
    CreateCaseComponent,
    ItemizedLineItemComponent,
    ItemizedLineItemGridComponent,
    ItemizedListComponent,
    ItemizedRevisionListComponent,
    ItemizedRevisionRevenueCodeSummaryComponent,
    ItemizedRevisionSelectDialogComponent,
    MICaseComponent,
    MiCaseGeneralComponent,
    MiCaseUsersComponent,
    CaseEditComponent,
    CaseHoldDialogComponent,
    MiCaseFinanceComponent,
    PrimaryClientEditDialogComponent,
    MiCaseReportElementsComponent,
    MiCaseReportElementPublishDateEditDialogComponent,
    MiCaseAppealLevelComponent,
    MiCaseAppealLevelCreateDialogComponent
  ],
  exports: [
    ActivityDiaryListComponent,
    CaseHoldDialogComponent,
    CaseListComponent,
    CreateCaseComponent,
    ItemizedLineItemComponent,
    ItemizedLineItemGridComponent,
    ItemizedListComponent,
    ItemizedRevisionListComponent,
    ItemizedRevisionRevenueCodeSummaryComponent,
    MICaseComponent,
    MiCaseUsersComponent,
    AddCommentComponent
  ],
  providers: [
    ItemizedRevisionService
  ]
})
export class MICaseModule {}
