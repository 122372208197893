import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CommentListDialogComponent } from '@app/comments/comment-list-dialog/comment-list-dialog.component';
import { Subject } from 'rxjs';
import {
  ItemizedRevisionCloneDialogComponent
} from '../itemized-revision-clone-dialog/itemized-revision-clone-dialog.component';
import { ItemizedRevisionService } from '../itemized-revision.service';
import {
  ClientReportPacket,
  FennecSnackbarService,
  ItemizedRevisionExportPacket,
  ProviderReportPacket,
  SingleChoiceDialogComponent
} from 'xf-common';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {
  ItemizedRevisionEditDialogComponent
} from '../itemized-revision-edit-dialog/itemized-revision-edit-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';


@Component({
  selector: 'app-itemized-revision-list',
  templateUrl: './itemized-revision-list.component.html',
  styleUrls: ['./itemized-revision-list.component.scss']
})
export class ItemizedRevisionListComponent implements OnInit, AfterViewInit  {

  @Input()
  itemizedId: string = "-1";

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  // Fires when the user requests to go to the itemized line item component.
  itemizedLineItemComponentRequested: Subject<any> = new Subject<any>();

  // Full list of itemized revisions
  itemizedRevisionList: any [] = [];
  selectedItemizedRevisionId: string = "-1";
  // Used for UI
  selectedItemizedRevision: any = null;

  // Used for Front End Card pagination
  itemizedRevisionViewAmount: number = 4;
  itemizedRevisionViewList: any [] = [];
  currentIndex:number = 0;

  miCaseId:string|null = null;

  isUserAdmin: boolean = false;
  canUserExportRevision:boolean = false;
  canUserGenerateAppealReport = false;
  canUserDeactivateRevisions = false;

  defaultPageSize:number = 4;
  totalRowCount:number = 0;

  constructor(
    private itemizedRevisionService: ItemizedRevisionService,
    private snack: FennecSnackbarService,
    public matDialog: MatDialog,
    protected activeRoute: ActivatedRoute,
    protected keycloakService: KeycloakService,
  ) {
    this.miCaseId = this.activeRoute.parent?.snapshot.paramMap.get("miCaseId") ?? null;
    const roles = keycloakService.getUserRoles();
    this.isUserAdmin = roles.includes("ADMIN");
    this.canUserExportRevision = roles.includes("ITEMIZED_REVISION_EXPORT");
    this.canUserGenerateAppealReport = roles.includes("APPEAL_REPORT_GENERATE");
    this.canUserDeactivateRevisions = roles.includes("ITEMIZED_REVISION_DEACTIVATE");
   }

  ngAfterViewInit(): void {
 
  }

  ngOnInit(): void {
    this.getItemizedRevisions(parseInt(this.itemizedId, 10));
  }

  getSelectedStyle(id: number) {
    if (id.toString() === this.selectedItemizedRevisionId) {
      return {
        "background-color": "powderblue"
      }
    } else {
      return {
        "background-color": "#E5E6EB"
      }
    }
  }

  getItemizedRevisionListDisplayColCount(): number {
    if (this.itemizedRevisionList.length > 0) {
      return this.itemizedRevisionList.length;
    } else {
      return 2;
    }
  }

  getSelectedItemizedRevision(): any {
    let iRev = null;
    if (this.selectedItemizedRevisionId === "-1") {
      return null;
    } else {
      this.itemizedRevisionList.forEach((ir) => {
        if (ir.id === parseInt(this.selectedItemizedRevisionId, 10)) {
          iRev = ir;
        }
      });
    }
    return iRev;
  }

  isSelectedRevisionAPEProcessed(): boolean {
    let iRev = this.getSelectedItemizedRevision();
    return iRev !== null && iRev.apeProcessed;
  }

  isSelectedRevisionReportMaster(): boolean {
    let iRev = this.getSelectedItemizedRevision();
    return iRev !== null && iRev.reportMaster;
  }

  getItemizedRevisions(itemizedId: number) {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.itemizedRevisionService.getItemizedRevisionListForItemizedPaginated(itemizedId, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.itemizedRevisionList = response.data;
        this.totalRowCount = response['totalRowCount'];

        if (this.selectedItemizedRevisionId === "-1") {
          if (this.itemizedRevisionList.length > 0) {
            this.selectItemizedRevision(this.itemizedRevisionList[0]);
          } 
        } else {
          this.itemizedRevisionList.forEach((irev) => {
            if (parseInt(irev.id, 10) === parseInt(this.selectedItemizedRevision.id, 10)) {
              this.selectItemizedRevision(irev);
            }
          });
        }

      }
    });
  }

  pageHandler() {
    this.getItemizedRevisions(parseInt(this.itemizedId, 10));
  }

  createManualItemizedRevision() {
    alert("Coming soon!");
  }

  createUploadItemizedRevision() {
    alert("Coming soon!");
  }

  // selectItemizedRevision(id: number) {
  //   this.selectedItemizedRevisionId = id.toString();
  // }

  selectItemizedRevision(itemizedRevision:any) {
    this.selectedItemizedRevisionId = itemizedRevision.id.toString();
    this.selectedItemizedRevision = itemizedRevision;
  }

  cloneItemizedRevision() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      iRevId: this.selectedItemizedRevisionId
    };
    const dialogRef = this.matDialog.open(ItemizedRevisionCloneDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getItemizedRevisions(parseInt(this.itemizedId, 10));
      }
    });

  }

  sendToAPE() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `Send ItemizedRevision #${this.selectedItemizedRevisionId} to APE?`,
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.itemizedRevisionService.sendItemizedRevisionToAPE(parseInt(this.selectedItemizedRevisionId)).subscribe(result => {
          if (result.hasErrors) {
            this.snack.showErrorSnack("Error sending to APE");
          } else {
            this.snack.showWorkerSubmitSnack("Sent to APE!");
          }
        });
      }
    });
  }

  goToItemizedLineItems() {
    this.itemizedLineItemComponentRequested.next(this.selectedItemizedRevisionId);
  }

  createProviderReportPDF() {
    let iRev = this.getSelectedItemizedRevision();
    if (iRev === null || iRev === undefined) {
      return;
    }

    const packet = new ProviderReportPacket();
    packet.itemizedRevisionId = parseInt(this.selectedItemizedRevisionId);
    if (iRev.miCaseId){
      packet.miCaseId = parseInt(iRev.miCaseId);
    }

    this.itemizedRevisionService.generateItemizedProviderReport(packet).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.snack.showWorkerSubmitSnack("Generating Itemized Provider Report!");
      }
    });
  }

  createItemizedClientReportPDF() {
    let iRev = this.getSelectedItemizedRevision();
    if (iRev === null || iRev === undefined) {
      return;
    }

    const packet = new ClientReportPacket();
    packet.itemizedRevisionId = parseInt(this.selectedItemizedRevisionId);
    if (iRev.miCaseId){
      packet.miCaseId = parseInt(iRev.miCaseId);
    }

    this.itemizedRevisionService.generateItemizedClientReport(packet).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.snack.showWorkerSubmitSnack("Generating Itemized Client Report");
      }
    });

  }

  designateReportMaster() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `Designate ItemizedRevision #${this.selectedItemizedRevisionId} as Report Master Itemized Revision?`,
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.itemizedRevisionService.setItemizedRevisionToReportMaster(parseInt(this.itemizedId), parseInt(this.selectedItemizedRevisionId)).subscribe(result => {
          if (result.hasErrors) {
            this.snack.showErrorSnack("Error setting Report Master");
          } else {
            this.snack.showSuccessSnack("Report Master successfully set to Itemized Revision ID:" + this.selectedItemizedRevisionId);
            this.getItemizedRevisions(parseInt(this.itemizedId, 10));
          }
        });
      }
    });
  }

  openCommentDialog = () => {
    if(this.selectedItemizedRevisionId === "-1") {
      return;
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "75vw";
    matDialogConfig.data = {
      mode: "ITEMIZED_REVISION",
      relatedId: this.selectedItemizedRevisionId
    }
    const dialogRef = this.matDialog.open(CommentListDialogComponent, matDialogConfig);
  }

  getItemizedRevisionViewList(index:number) {
    const result = [];
    for(let i = 0; i < this.itemizedRevisionViewAmount; i++) {
      const ItemizedRevision = this.getItemizedRevisionAtIndex(index + i);
      if(ItemizedRevision != null) {
        result.push(ItemizedRevision);
      }
    }

    this.currentIndex = index;
    this.itemizedRevisionViewList = result;
  }

  getItemizedRevisionAtIndex(index:number) {
    if(index < this.itemizedRevisionList.length) {
      return this.itemizedRevisionList[index]
    }

    return null;
  }

  handlePaginator = (event:any) => {
    this.getItemizedRevisionViewList(this.currentIndex + (
      this.itemizedRevisionViewAmount *
      (event.pageIndex - event.previousPageIndex)
    ));
  }

  openEditRevisionTypeDialog = () => {
    if(this.selectedItemizedRevisionId === "-1") {
      return;
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      revisionType: this.selectedItemizedRevision.itemizedRevisionReviewType,
      id: this.selectedItemizedRevision.id,
      name: this.selectedItemizedRevision.name
    }
    const dialogRef = this.matDialog.open(ItemizedRevisionEditDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirm) {
        this.getItemizedRevisions(parseInt(this.itemizedId, 10));
      }
    })
  }

  exportToXLSX = () => {
    const revision = this.selectedItemizedRevision;
    const packet = new ItemizedRevisionExportPacket();
    packet.itemizedRevisionId = revision.id;

    if (this.miCaseId != null) {
      packet.miCaseId = parseInt(this.miCaseId);
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `Export Itemized Revision ID:${revision.id} '${revision.name}' to XLSX?`,
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirm) {
        this.itemizedRevisionService.generateItemizedRevisionXLSX(packet).subscribe((response) => {
          if (response.hasErrors) {
            this.snack.showErrorSnack("Error requesting XLSX Export");
          }else {
            this.snack.showWorkerSubmitSnack("Exporting Itemized Revision to XLSX");
          }
        });
      }
    })
  }

  generateAppealReport = () => {
    const revision = this.selectedItemizedRevision;

    let caseId: null | number = null;
    if (this.miCaseId != null) {
      caseId = parseInt(this.miCaseId);
    }

    const packet = {
      miCaseId: caseId,
      itemizedRevisionId: revision.id,
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `Generate Appeal Report for ID:${revision.id} '${revision.name}'?`,
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirm) {
        this.itemizedRevisionService.generateAppealReport(packet).subscribe((response) => {
          if (response.hasErrors) {
            this.snack.showErrorSnack("Error requesting Appeal Report");
          }else {
            this.snack.showWorkerSubmitSnack("Generating Appeal Report");
          }
        });
      }
    })
  }

  deactivateItemizedRevision = () => {
    const revision = this.selectedItemizedRevision;
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `Deactivate Itemized Revision ID ${revision.id} '${revision.name}'?`,
      infoLine1: "This revision will be permanently hidden from the UI"
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirm) {
        this.itemizedRevisionService.deactivateItemizedRevision(revision.id).subscribe((response) => {
          if (response.hasErrors) {
            this.snack.showErrorSnack("Error Deactivating Itemized Revision");
          }else {
            this.snack.showSuccessSnack("Revision Deleted");
            this.getItemizedRevisions(parseInt(this.itemizedId, 10));
          }
        });
      }
    })
  }
}
