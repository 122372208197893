import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BaseComponent, FennecSnackbarService, Logger } from 'xf-common';
import { MiCaseAppealLevelService } from '../mi-case-appeal-level.service';

@Component({
  selector: 'app-mi-case-appeal-level-create-dialog',
  templateUrl: './mi-case-appeal-level-create-dialog.component.html',
  styleUrls: ['./mi-case-appeal-level-create-dialog.component.css']
})
export class MiCaseAppealLevelCreateDialogComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("MiCaseAppealLevelCreateDialogComponent");

  mode: string = "CREATE";
  formGroup!: FormGroup;
  appealLevelTypeList: any [] = [];
  appealTypeList: any [] = [];

  // For creating and editing purposes
  miCaseId: number = -1;
  miCaseAppealLevelId: number = -1;
  itemizedRevisionId: number = -1;
  appealType: string | null = null;
  appealLevelType: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<MiCaseAppealLevelCreateDialogComponent>,
    private miCaseAppealLevelService: MiCaseAppealLevelService,
    protected snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = data.mode;
    this.miCaseId = data.miCaseId;
    this.miCaseAppealLevelId = data.id;
    this.itemizedRevisionId = data.itemizedRevisionId;
    this.appealLevelType = data.appealLevelType;
    this.appealType = data.appealType;
    this.formGroup = new FormGroup({
      appealType: new FormControl(this.appealType !== null ? this.appealType : "", Validators.required) 
    });

  }

  ngOnInit() {
    this.getAppealLevelTypes();
    this.getAppealTypes();
  }

  getAppealLevelTypes() {
    this.performXFRequest({
      requestDescription: "Get Appeal Level Types",
      requestFn: this.miCaseAppealLevelService.getAppealLevelTypes,
      fnParams: [],
      onSuccess: data => {
        this.appealLevelTypeList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  getAppealTypes() {
    this.performXFRequest({
      requestDescription: "Get Appeal Types",
      requestFn: this.miCaseAppealLevelService.getAppealTypes,
      fnParams: [],
      onSuccess: data => {
        this.appealTypeList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }  

  onSubmit() {
    if (this.mode === "CREATE") {
      this.performXFRequest({
        requestDescription: "Create Next Appeal Level",
        requestFn: this.miCaseAppealLevelService.createNext,
        fnParams: [this.miCaseId, this.formGroup.controls['appealType'].value],
        onSuccess: data => {
          const returnObj = {
            confirm: true
          }
          this.dialogRef.close(returnObj);
        },
        onError: errString => {
          super.showErrorSnack(errString);
        }
      });
    } else {
      const packet = {
        id: this.miCaseAppealLevelId,
        miCaseId: this.miCaseId,
        itemizedRevisionId: this.itemizedRevisionId === null ? -1 : this.itemizedRevisionId,
        appealType: this.formGroup.controls['appealType'].value,
        appealLevelType: this.appealLevelType
      }
      this.performXFRequest({
        requestDescription: "Update Appeal Level",
        requestFn: this.miCaseAppealLevelService.update,
        fnParams: [packet],
        onSuccess: data => {
          const returnObj = {
            confirm: true
          }
          this.dialogRef.close(returnObj);
        },
        onError: errString => {
          super.showErrorSnack(errString);
        }
      });      
    }
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

}
