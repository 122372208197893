<mat-dialog-content>
  <h1 mat-dialog-title>Select Itemized Revision</h1>
  <div class="create-form">
    <form [formGroup]="formGroup" class="container create-form">
      <mat-form-field appearance="outline" class="type-input">
        <mat-label>Itemized</mat-label>
        <mat-select
          formControlName="itemizedId"
          >
          <mat-option *ngFor="let i of itemizedList" [value]="i.id">{{ i.externalAltId }}</mat-option>
        </mat-select>
      </mat-form-field>     
    </form>
  </div>

  <mat-table [dataSource]="itemizedRevisionList">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.id}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.name}} </mat-cell>
    </ng-container>  
    
    <ng-container matColumnDef="itemizedRevisionReviewType">
      <mat-header-cell *matHeaderCellDef> Review Type </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.itemizedRevisionReviewType}} </mat-cell>
    </ng-container> 

    <ng-container matColumnDef="reportMaster">
      <mat-header-cell *matHeaderCellDef> Rpt Mstr </mat-header-cell>
      <mat-cell *matCellDef="let c"> 
        <span *ngIf="c.reportMaster">RPT-MSTR</span>
        <span *ngIf="!c.reportMaster"> </span>
      </mat-cell>
    </ng-container>   
    
    <ng-container matColumnDef="totalBilledAmount">
      <mat-header-cell *matHeaderCellDef> Billed </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.info.totalBilledAmount | currency}} </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="totalAdjustedAmount">
      <mat-header-cell *matHeaderCellDef> Adj </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.info.totalAdjustedAmount | currency}} </mat-cell>
    </ng-container>  
    
    <ng-container matColumnDef="totalFindingsAmount">
      <mat-header-cell *matHeaderCellDef> Apl Findings </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.info.totalFindingsAmount | currency}} </mat-cell>
    </ng-container>  
    
    <ng-container matColumnDef="totalOverturnedAmount">
      <mat-header-cell *matHeaderCellDef> Apl OverTurned </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.info.totalOverturnedAmount | currency}} </mat-cell>
    </ng-container>  
    
    <ng-container matColumnDef="totalUpheldAmount">
      <mat-header-cell *matHeaderCellDef> Apl Upheld </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.info.totalUpheldAmount | currency}} </mat-cell>
    </ng-container>    
    
    <ng-container matColumnDef="createdDate">
      <mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.createdDate | date: 'MM/dd/yyyy'}} </mat-cell>
    </ng-container>  
    
    <ng-container matColumnDef="selectAction">
      <mat-header-cell *matHeaderCellDef> Select </mat-header-cell>
      <mat-cell *matCellDef="let c"> 
        <button mat-flat-button (click)="selectItemizedRevision(c)" color="accent">
          <mat-icon>save</mat-icon> Select
      </button>
      </mat-cell>
    </ng-container>    

    <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns;"
      [style.background-color]="selectedItemizedRevision?.id === row.id ? 'yellow' : ''"
    >
    </mat-row>
  </mat-table>

  <mat-paginator
  [pageSize]="defaultPageSize"
  [length]="totalRowCount"
  (page)="pageHandler()"
></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
  <!--
    <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent">
        <mat-icon>save</mat-icon> Create
    </button> -->

    <button mat-flat-button (click)="onSubmit()" color="accent" [disabled]="selectedItemizedRevision === null">
      <mat-icon>save</mat-icon> Save/Update
  </button>

    <button mat-button mat-stroked-button (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>

