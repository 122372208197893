import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserProfileService } from '@app/user/user-profile.service';
import {
  BaseComponent,
  CommentPacket,
  CommentRelationalMapMultiCreatePacket,
  CommentRelationalMapPacket, DEFAULT_TEXT_INTERNAL_ADJUSTMENT_COMMENT,
  FennecSnackbarService,
  Logger,
  UserProfilePacket
} from 'xf-common';
import { Subject } from 'rxjs';
import { CommentService } from '../comment.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';


@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent extends BaseComponent implements OnInit {

  protected log: Logger = new Logger("AddCommentComponent");

  formGroup: FormGroup;

  // MODES: CREATE && EDIT && MULTI
  @Input()
  mode: string = "CREATE";

  @Input()
  relationalType: string = "MI_CASE";

  @Input()
  relatedId: string = "-1";

  @Input()
  relatedIdList: number[] = [];

  @Input()
  formattedUsername: String = "";

  uniqueRefId: string = "";

  userProfile?: UserProfilePacket;

  commentTypes?: string[] = [];
  selectedCommentType = "NONE";

  saveCommentComplete: Subject<any> = new Subject();
  cancelComment: Subject<any> = new Subject();

  existingComment?: any;

  // Used to prevent comment dialog from closing parent dialog
  @Input()
  embeddedInDialog = false;
  constructor(
    override snack: FennecSnackbarService,
    protected commentService: CommentService,
    protected userProfileService: UserProfileService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<AddCommentComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any

  ) {
    super();
    if (data) {
      this.mode = data.mode;
      this.existingComment = data.comment;
      if (data.comment !== null && data.comment !== undefined) {
        this.selectedCommentType = !!data.comment.commentType ? data.comment.commentType : "NONE";
      }
      this.formattedUsername = data.formattedUsername;

      if(data.relatedId) {
        this.relatedId = data.relatedId;
      }

      if(data.relatedIdList) {
        this.relatedIdList = data.relatedIdList;
      }
      this.relationalType = data.relationalType;
      this.uniqueRefId = data.uniqueRefId;
    }

    this.formGroup = this.createFormGroup(this.existingComment);
   }

  ngOnInit(): void {
    this.performXFRequest({
      requestDescription: "GET Comment Types",
      requestFn: this.commentService.getCommentTypes,
      fnParams: [this.relationalType],
      onSuccess: (data) => {
        // this.userProfile = data;
        this.commentTypes = data;
      },
      onComplete: () => {
        // this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    })

    if(this.userProfile == null) {
      this.getUserProfile();
    }
  }

  createFormGroup = (existingComment:any = null) => {
    if (existingComment != null) {
      this.selectedCommentType = existingComment?.commentType ?? "NONE";
    }
    return new FormGroup({
      commentText: new FormControl(existingComment ? existingComment?.commentText : "")
    });
  }

  clearFormGroup = () => {
    this.formGroup = this.createFormGroup();
  }

  cancelAdd = () => {
    this.clearFormGroup();
    // this.cancelComment.next("OK");
    this.dialogRef.close();
  }

  saveCommentToServer = () => {
    const commentPacket = new CommentPacket();

    commentPacket.commentText = this.formGroup.controls["commentText"].value;
    commentPacket.commentType = this.selectedCommentType;

    const packet = new CommentRelationalMapPacket();
    packet.comment = commentPacket;
    packet.relatedId = parseInt(this.relatedId);
    packet.commentRelationalType = this.relationalType;

    if(this.relationalType == "MI_CASE") {
      packet.miCaseId = packet.relatedId;
    }

    if(this.uniqueRefId !== "") {
      packet.lineItemUniqueRefId = this.uniqueRefId;
    }

    // Changes function/params depending on mode
    let requestFunction = this.commentService.createComment
    let params = packet;

    if(this.mode === "EDIT") {
      requestFunction = this.commentService.editComment;
      commentPacket.id = this.existingComment.id;
      params = commentPacket;
    }

    if(this.mode === "MULTI") {
      requestFunction = this.commentService.createMultiComment;
      const multiCreatePacket = new CommentRelationalMapMultiCreatePacket();
      multiCreatePacket.relatedIds = this.relatedIdList;
      multiCreatePacket.commentRelationalMapPacket = packet;

      params = multiCreatePacket;
    }

    this.performXFRequest({
      requestDescription: "Save Comment",
      requestFn: requestFunction,
      fnParams: [params],
      onSuccess: (data) => {
        this.clearFormGroup();
        this.showSuccessSnack("Comment Added!");
        if(!this.embeddedInDialog) {
          this.dialogRef.close();
        }
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    })
  }

  // Returns True if commentText is empty.  Used to prevent Validators.required always discoloring comment input on component load
  manualValidation = () => {
    const control = this.formGroup.controls['commentText'];
    if(control && control.value) {
      return control.value.length === 0;
    }

    return true;
  }

  getUserProfile = () => {
    this.performXFRequest({
      requestDescription: "GET User Profile",
      requestFn: this.userProfileService.getUserProfile,
      fnParams: [],
      onSuccess: (data) => {
        this.userProfile = data;
        this.formattedUsername = this.getFormattedUsername();
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    })
  }

  getFormattedUsername = (): String => {
    if(!this.userProfile) {
      return "";
    }

    return `${this.userProfile.firstName ?? ""} ${this.userProfile.lastName ?? ""}`
  }

  onSelectedCommentTypeChange() {
    if (this.selectedCommentType === "CLIENT_INTERNAL_ADJUSTMENT") {
      const commentTextControl = this.formGroup.controls["commentText"];
      if (commentTextControl?.value != null && commentTextControl?.value != "")
        return;
      commentTextControl.setValue(DEFAULT_TEXT_INTERNAL_ADJUSTMENT_COMMENT);
    }
  }
}
