import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BaseComponent, FennecSnackbarService, ItemizedService, Logger } from 'xf-common';
import { MiCaseAppealLevelService } from '../../../mi-case/mi-case-appeal-level/mi-case-appeal-level.service';
import { ItemizedRevisionService } from '../itemized-revision.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-itemized-revision-select-dialog',
  templateUrl: './itemized-revision-select-dialog.component.html',
  styleUrls: ['./itemized-revision-select-dialog.component.css']
})
export class ItemizedRevisionSelectDialogComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("ItemizedRevisionSelectDialogComponent");

  formGroup!: FormGroup;
  itemizedList: any [] = [];

  initialSelectedItemizedRevisionId: number = -1;
  selectedItemizedRevision: any = null;

  miCaseId: number = -1;

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  defaultPageSize:number = 4;
  totalRowCount:number = 0;  

  itemizedRevisionList = new MatTableDataSource<any>()
  displayColumns = ['id', 'name', 'itemizedRevisionReviewType', 
    'totalBilledAmount', 'totalAdjustedAmount', 'totalFindingsAmount', 
    'totalOverturnedAmount', 'totalUpheldAmount',
    'createdDate', 'selectAction'];  

  constructor(
    public dialogRef: MatDialogRef<ItemizedRevisionSelectDialogComponent>,
    private itemizedRevisionService: ItemizedRevisionService,
    private itemizedService: ItemizedService,
    private miCaseAppealLevelService: MiCaseAppealLevelService,
    protected snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.miCaseId = data.miCaseId;
    this.initialSelectedItemizedRevisionId = data.itemizedRevisionId !== null ? data.itemizedRevisionId : -1;
    this.formGroup = new FormGroup({
      itemizedId: new FormControl(null, Validators.required) 
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.getItemizedRevisions(this.formGroup.controls['itemizedId'].value);
    });    

  }

  ngOnInit() {
    this.getItemizedList();
  }

  getItemizedList() {
    if (this.miCaseId === null || this.miCaseId === undefined) {
      return;
    }
    this.itemizedService.getAllItemizedForMICasePaginated(this.miCaseId, 0, 9999).subscribe((response: any) => {
      if (response.hasErrors) {
        this.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.itemizedList = response.data;
        if (this.itemizedList.length > 0) {
          // This assignment should trigger a 'valueChanges' event that fetches the itemized revision info for
          // this itemized.
          this.formGroup.controls['itemizedId'].setValue(this.itemizedList[0].id);
        }
      }
    });
  }

  getItemizedRevisions(itemizedId: number) {
    this.itemizedRevisionList.data = [];
    if (itemizedId === null || itemizedId === undefined) {
      return;
    }
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;    
    this.itemizedRevisionService.getItemizedRevisionListForItemizedPaginated(itemizedId, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.itemizedRevisionList.data = response.data;
        this.totalRowCount = response['totalRowCount'];
        if (this.initialSelectedItemizedRevisionId >= 0) {
          this.itemizedRevisionList.data.forEach((iRev) => {
            if (iRev.id === this.initialSelectedItemizedRevisionId) {
              this.selectedItemizedRevision = iRev;
              this.initialSelectedItemizedRevisionId = -1;
            }
          });
        }
      }
    });
  }

  pageHandler() {
    this.getItemizedRevisions(parseInt(this.formGroup.controls['itemizedId'].value, 10));
  }  

  onSubmit() {
    const returnObj = {
      confirm: true,
      itemizedRevisionId: this.selectedItemizedRevision?.id
    }
    this.dialogRef.close(returnObj);
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  selectItemizedRevision(iRev: any) {
    this.initialSelectedItemizedRevisionId = -1;
    this.selectedItemizedRevision = iRev;
  }

}
